import { useState, useEffect, useCallback, useRef } from "react";
import { useSelectedOption } from "../../contexts/SelectedOptionContext";
import AboutMe from "../AboutMe/AboutMe";
import Projects from "../Projects/Projects";
import MovieBlog from "../MovieBlog/MovieBlog";
import Her from "../MovieBlog/Analysis/Her/Her";
import Fox from "../MovieBlog/Analysis/Fox/Fox";
import MissSunshine from "../MovieBlog/Analysis/MissSunshine/MissSunshine";
import Contact from "../Contact/Contact";
import "./Showcase.css";

const Showcase = ({ onMinimize, onExit }) => {
  const { selectedOption } = useSelectedOption();
  const { setSelectedOption } = useSelectedOption();
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [isMaximized, setIsMaximized] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [size, setSize] = useState({ width: 800, height: 600 });
  const [isResizing, setIsResizing] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");
  const iconRef = useRef(null);
  const audioContextRef = useRef(null);
  const mouseDownBufferRef = useRef(null);
  const mouseUpBufferRef = useRef(null);

  const blackLeftTriangle30 =
    process.env.PUBLIC_URL + "/icons/blackLeftTriangle30.png";

  const renderContent = () => {
    switch (selectedOption) {
      case "AboutMe":
        return <AboutMe handleOptionChange={handleOptionChange} />;
      case "Projects":
        return (
          <Projects
            selectedProject={selectedProject}
            clearSelection={() => setSelectedProject("")}
          />
        );
      case "MovieBlog":
        return <MovieBlog onOptionChange={setSelectedOption} />;
      case "Contact":
        return <Contact />;
      case "Her":
        return <Her />;
      case "Fox":
        return <Fox />;
      case "MissSunshine":
        return <MissSunshine />;
      default:
        return <AboutMe />;
    }
  };

  const handleProjectSelection = (projectName) => {
    setSelectedProject(projectName);
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleMouseDown = useCallback(
    (e) => {
      setIsDragging(true);
      setStartPosition({
        x: e.clientX - position.x,
        y: e.clientY - position.y,
      });
    },
    [position.x, position.y]
  );

  const handleMouseMove = useCallback(
    (e) => {
      if (isDragging) {
        const vw = Math.max(
          document.documentElement.clientWidth || 0,
          window.innerWidth || 0
        );
        const vh = Math.max(
          document.documentElement.clientHeight || 0,
          window.innerHeight || 0
        );

        let newX = e.clientX - startPosition.x;
        let newY = e.clientY - startPosition.y;

        const commandLineRect = iconRef.current.getBoundingClientRect();

        const maxX = vw - 5 - commandLineRect.width;
        const maxY = vh - 35 - commandLineRect.height;

        newX = Math.min(Math.max(newX, 5), maxX);
        newY = Math.min(Math.max(newY, 5), maxY);

        setPosition({ x: newX, y: newY });
      }
    },
    [isDragging, startPosition.x, startPosition.y]
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const maximizeClick = useCallback(() => {
    setIsMaximized(!isMaximized);
  }, [isMaximized]);

  const preventDragHandler = useCallback((e) => {
    e.preventDefault();
  }, []);

  const stopPropagationHandler = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const handleResize = useCallback(
    (e) => {
      if (isResizing) {
        const newWidth = Math.max(
          660,
          e.clientX - iconRef.current.getBoundingClientRect().left
        );
        const newHeight = Math.max(
          450,
          e.clientY - iconRef.current.getBoundingClientRect().top
        );

        setSize({ width: newWidth, height: newHeight });
      }
    },
    [isResizing]
  );

  const startResizing = useCallback((e) => {
    e.preventDefault();
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  useEffect(() => {
    const handleResize = () => {
      setPosition({
        x: (window.innerWidth - 800) / 2, //change this when you change cmd width
        y: (window.innerHeight - 650) / 2, //change this when you change cmd height
      });
    };

    handleResize();
  }, []);

  useEffect(() => {
    const handleMouseUp = () => stopResizing();
    const handleMouseMove = (e) => handleResize(e);

    if (isResizing) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing, handleResize, stopResizing]);

  const initializeAudio = async () => {
    if (audioContextRef.current === null) {
      audioContextRef.current = new (window.AudioContext ||
        window.AudioContext)();
    }

    const audioContext = audioContextRef.current;

    const loadSound = async (url) => {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      return audioContext.decodeAudioData(arrayBuffer);
    };

    mouseDownBufferRef.current = await loadSound(
      `${process.env.PUBLIC_URL}/sound/mousedown.mp3`
    );
    mouseUpBufferRef.current = await loadSound(
      `${process.env.PUBLIC_URL}/sound/mouseup.mp3`
    );
  };

  const playSound = (audioBuffer) => {
    if (audioContextRef.current && audioBuffer) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContextRef.current.destination);
      source.start();
    }
  };

  useEffect(() => {
    initializeAudio();

    return () => {
      audioContextRef.current?.close();
    };
  }, []);

  const handleMouseSoundDown = (e) => {
    stopPropagationHandler(e);
    playSound(mouseDownBufferRef.current);
  };

  const handleMouseMinimize = (e) => {
    if (windowWidth >= 768) {
      stopPropagationHandler(e);
      playSound(mouseUpBufferRef.current);
      onMinimize();
    }
  };

  const handleMouseMaximize = (e) => {
    if (windowWidth >= 768) {
      stopPropagationHandler(e);
      playSound(mouseUpBufferRef.current);
      maximizeClick();
    }
  };

  const handleMouseExit = (e) => {
    if (windowWidth >= 768) {
      stopPropagationHandler(e);
      playSound(mouseUpBufferRef.current);
      onExit();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const isMobile = windowWidth < 768;
    setIsMaximized(isMobile);
  }, [windowWidth]);

  const showcaseStyle = isMaximized
    ? {
        position: "fixed",
        width: "calc(100vw - 10px)",
        height: "calc(100vh - 41px)",
        top: "5px",
        left: "5px",
        right: "5px",
        bottom: "36px",
        margin: 0,
        padding: 0,
      }
    : {
        left: `${position.x}px`,
        top: `${position.y}px`,
        position: "absolute",
        width: `${size.width}px`,
        height: `${size.height}px`,
      };

  return (
    <div className="showcase" style={showcaseStyle} ref={iconRef}>
      <div
        className="showcase-header"
        onMouseDown={handleMouseDown}
        onDoubleClick={handleMouseMaximize}
      >
        <div className="header-labels">
          <img
            className="internet-explorer-icon"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAtklEQVQ4jZ1TWwrEIAycWfZezc2a3Cw5WfZDWrU2bOlAEKLOwwdVNfECqorMJFQ13wBAZiY+TxXNDCQQEYuVhd3dE8ipuvIfByQgsi0OyEPQpv5EYNYm3WMaR5J93+sI3V6LMfbXKJcIZgb3OK2KbCC79QongYhM2d3jcoytSgJ3nyZENkQEIuJ0cuvmOIMxf1WPHlLmelVH/w7fcQHZNotIuaEk6Cr7/coCBPDqNzbBJPOp1wI/IRQHeWjw+PEAAAAASUVORK5CYII="
            alt="internet explorer"
          />
          <span className="showcase-title">Matia Lee - Portfolio 2024</span>
          <div className="border-extension"></div>
        </div>
        <div className="icons">
          <img
            className="showcase-minimize-icon"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAKCAYAAACALL/6AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAKElEQVQYlWP8//8/AymAiSTVI1UDC4zByMiINXz////PSJENjKRGHAAvZgoPiLbuwwAAAABJRU5ErkJggg=="
            alt="minimize"
            onDragStart={preventDragHandler}
            onMouseDown={handleMouseSoundDown}
            onMouseUp={handleMouseMinimize}
          />
          <img
            className="showcase-maximize-icon"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAKCAYAAACALL/6AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAOUlEQVQYld2RMQoAIAzEcuL/vxw3kSKoq5lzbelFJYkcUAPQTmKl1wk71gueN/wQmF+66QIgeuVNBqtLDxPoqi0wAAAAAElFTkSuQmCC"
            alt="maximize"
            onDragStart={preventDragHandler}
            onMouseDown={handleMouseSoundDown}
            onMouseUp={handleMouseMaximize}
          />
          <img
            className="showcase-exit-icon"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAKCAYAAACALL/6AAAACXBIWXMAAAsTAAALEwEAmpwYAAAATUlEQVQYlZ2RQQ7AIAzD4v3/z+HEVLogwXpC1FZTwLZu6pkHwMDH7vevYJsJVLj2JIkeqU+p8DIhAR2OQoq0FWrmtNMipAXjQ/z+h9MaAucxEWL07JoAAAAASUVORK5CYII="
            alt="exit"
            onDragStart={preventDragHandler}
            onMouseDown={handleMouseSoundDown}
            onMouseUp={handleMouseExit}
          />
        </div>
      </div>
      <div className="navigation-bar">
        {/* <div className="navigation-bar-title-container">
          <div className="navigation-bar-title">Matia Lee</div>
          <div className="navigation-bar-subtitle">Portfolio 2024</div>
        </div> */}
        <div className="about-me-select">
          {selectedOption === "AboutMe" && (
            <img src={blackLeftTriangle30} alt="Left Triangle" />
          )}
          <p onClick={() => handleOptionChange("AboutMe")}>ABOUT ME</p>
        </div>
        <div className="about-me-select">
          {selectedOption === "Projects" && (
            <img src={blackLeftTriangle30} alt="Left Triangle" />
          )}
          <p onClick={() => handleOptionChange("Projects")}>PROJECTS</p>
        </div>
        <div className="projects-navigation">
          {selectedOption === "Projects" && (
            <div className="projects-list">
              <ul style={{ listStyleType: "none" }}>
                <li onClick={() => handleProjectSelection("uwcourses")}>
                  uwcourses
                </li>
                <li onClick={() => handleProjectSelection("stamped")}>
                  stamped
                </li>
                <li onClick={() => handleProjectSelection("matialee")}>
                  matialee.com
                </li>
                <li onClick={() => handleProjectSelection("letterstats")}>
                  Letterstats
                </li>
                <li onClick={() => handleProjectSelection("outfitly")}>
                  Outfitly
                </li>
                <li onClick={() => handleProjectSelection("pomodoro")}>
                  Pomodoro
                </li>
                <li onClick={() => handleProjectSelection("cineberg")}>
                  Cineberg
                </li>
                <li onClick={() => handleProjectSelection("pattywagon")}>
                  Patty Wagon
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className="about-me-select">
          {selectedOption === "MovieBlog" && (
            <img src={blackLeftTriangle30} alt="Left Triangle" />
          )}
          <p onClick={() => handleOptionChange("MovieBlog")}>MOVIE BLOG</p>
        </div>
        <div className="about-me-select">
          {selectedOption === "Contact" && (
            <img src={blackLeftTriangle30} alt="Left Triangle" />
          )}
          <p onClick={() => handleOptionChange("Contact")}>CONTACT</p>
        </div>
      </div>
      <div className="content">{renderContent()}</div>
      <div className="showcase-footer">
        <div className="separator"></div>
        <div className="showcase-footer-label">
          <p>© Copyright 2024 Matia Lee</p>
        </div>
        <div className="squares-container">
          <div className="square"></div>
          <div className="square"></div>
        </div>
        <div className="hiding"></div>
        <div className="footer-img">
          <img
            onDragStart={preventDragHandler}
            onMouseDown={startResizing}
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAUElEQVQokZXPqxUAIAxD0ZSREQgGQCBYOSg4/Fuib8QTkvgY3Q9OucB6YMoFAEyHjmPwoh0mDKVhw6+GI741XPGp4YnXBhWPDSbcGsw4Bi8V/BZRwMk/E5EAAAAASUVORK5CYII="
            alt="resize icon"
          />
        </div>
      </div>
    </div>
  );
};

export default Showcase;
